import React, { useRef, useState } from 'react';
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const Product = ({product, currency}) => {
    const [currentPrice, setCurrentPrice] = useState(product.price);
    const variantRef = useRef(null);
    const navigate = useNavigate();

          if (!product.is_active) {
            return null;
          }

    const handleBuyNow = async () => {
        const value = variantRef.current.value;
        navigate(`/payment?pid=${value}`);
    }

    const handleChangeVariant = (select) => {
        const code = select.target.value;
        const variant = product.variants.find(x => x.code === code);
        
        if(typeof(variant) !== "undefined") {
            setCurrentPrice(variant.price);
        }
    }

    return (
        <div className="product">
            <div className="image">
                <img src={product.images[0]} alt={product.name} crossOrigin="anonymous" />
            </div>
            <div className="details">
                <h3>{product.name}</h3>
                <h4>{currency}{Number(currentPrice).toFixed(2)}</h4>

                <div className="p">{parse(product.description)}</div>

                <div className="btn-wrapper">
                    <select className="form-control" ref={variantRef} onChange={handleChangeVariant}>
                        {product.variants.map(v => (<option key={v.code} value={v.code}>{v.count} {v.type}/ {v.dosage}</option>))}
                    </select>
                    <a href="#contact" className="btn">CONTACT US</a>
                </div>
            </div>
        </div>
    )
}

export default Product