import React from "react";

const ShippingRates = () => {
  return (
    <section
      id="shipping-rates"
      className="shipping-rates-section"
      style={styles.section}
    >
      <h2 style={styles.heading}>Shipping Rates</h2>
      <div style={styles.container}>
        <div style={styles.card}>
          <div style={styles.cardContent}>
            <span style={styles.option}>Priority Shipping</span>
            <span style={styles.price}>$19.99</span>
          </div>
        </div>
        <div style={styles.card}>
          <div style={styles.cardContent}>
            <span style={styles.option}>Express Shipping</span>
            <span style={styles.price}>$39.99</span>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: {
    padding: "60px 20px",
    background: "#ffffff",
    borderRadius: "1px",
    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.15)",
    maxWidth: "900px",
    margin: "40px auto",
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif",
  },
  heading: {
    fontSize: "38px",
    marginBottom: "30px",
    color: "#004d40",
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  },
  card: {
    width: "calc(50% - 20px)",
    minWidth: "280px",
    background: "linear-gradient(145deg, #ffffff, #f0f0f0)",
    borderRadius: "15px",
    padding: "25px",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  option: {
    fontSize: "22px",
    fontWeight: "700",
    color: "#333",
  },
  price: {
    fontSize: "22px",
    fontWeight: "700",
    color: "#007BFF",
    background: "linear-gradient(135deg, #007bff, #00bcd4)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  // Add hover effect
  "@media (hover:hover)": {
    card: {
      "&:hover": {
        transform: "translateY(-10px)",
        boxShadow: "0 12px 25px rgba(0, 0, 0, 0.2)",
      },
    },
  },
};

export default ShippingRates;
