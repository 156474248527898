import React, { useContext, useEffect, useState } from 'react';
import logo from "../assets/logo.png";
import { Link } from "react-scroll";
import ConfigContext from './context/ConfigContext';
import { useNavigate } from "react-router-dom";
import ProductContext from './context/ProductContext';

const Header = () => {
    const { config } = useContext(ConfigContext);
    const { setCategory } = useContext(ProductContext)

    const [open, setOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const [categories, setCategories] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
       
    }, [config]);

    const toggleMenu = (event) => {
        event.preventDefault();
        setOpen(prev => !prev);
    }

    const toggleCategory = (event) => {
        event.preventDefault();
        setOpenCategory(prev => !prev);
    }

    const handleClose = (element) => {
        setOpen(false);
        navigate(`/#${element}`);
    }

    const handleCategoryClick = (event, cat) => {
        event.preventDefault();
        setCategory(cat);
        navigate(`/?cat=${cat}`);
        setOpenCategory(false);
        setOpen(false);
    }

    return (
        <section className="head">
            <div className="header">
                <div className="logo">
                    <a href="/">
                        <img src={logo} alt="logo" />
                    </a>
                </div>

                <div className="desktop">
                    <Menu handleClose={handleClose} toggleCategory={toggleCategory} />
                </div>

                <div className="mobile">
                    <a href="#" onClick={toggleMenu}>
                        <span className="material-symbols-outlined">
                            menu
                        </span>
                    </a>
                </div>

                {open ? (
                    <div className="menu-container">
                        <Menu handleClose={handleClose} toggleCategory={toggleCategory} />
                    </div>
                ) : null}

                {openCategory ? (
                    <div className="category-list">
                        {categories.map(c => {
                            return (
                                <a href="#" key={c} className="category" onClick={(e) => { handleCategoryClick(e, c) }}>
                                    {c}
                                </a>
                            )
                        })}
                    </div>
                ) : null}
            </div>
        </section>
    )
}

const Menu = ({ handleClose, toggleCategory }) => {
    return (
        <div className="menu">
            <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => { handleClose("home") }}
            >Home
            </Link>

            <Link
                activeClass="active"
                to="featured"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => { handleClose("featured") }}
            >Featured Products
            </Link>

      <Link
        activeClass="active"
        to="shipping-rates"
        spy={true}
        smooth={true}
        offset={-100}
        duration={500}
        onClick={() => {
          handleClose("shipping-rates");
        }}
      >
        Shipping Rates
      </Link>

            <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => { handleClose("about") }}
            >About Us
            </Link>

            <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => { handleClose("contact") }}
            >Contact Us
            </Link>
        </div>
    )
}

export default Header